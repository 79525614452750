// Example Pagination.js file

import * as React from 'react'
import { Link } from 'gatsby'

// Create URL path for numeric pagination
const getPageNumberPath = (currentIndex) => {
    if (currentIndex === 0) {
        return '/articles'
    }
    return '/articles/' + (currentIndex + 1)
}

export const Pagination = ({ pageInfo }) => {
    if (!pageInfo) return null
    const { currentPage, pageCount } = pageInfo

    // Create URL path for previous and next buttons
    const prevPagePath = currentPage - 1 === 1 ? '/articles' : '/articles/' + (currentPage - 1).toString()
    const nextPagePath = '/articles/' + (currentPage + 1).toString()

    // Check if page is first or last to disable previous and next buttons
    const pagerCSS = ' p-1 border border-primary-300 bg-primary-50 rounded-sm hover:bg-primary-300'
    const prevClass = currentPage === 1 ? 'disabled' : 'enabled'
    const nextClass = currentPage === pageCount ? 'disabled' : 'enabled'

    // are we first or last page?
    const isFirst = currentPage === 1
    const isLast = currentPage === pageCount

    


    return (
        <div className="pagination flex justify-center space-x-1">

            {!isFirst && (
                <Link to={prevPagePath} className={pagerCSS+prevClass} rel="prev">
                    ← Previous Page
                </Link>
            )}

            {/*  Render numeric pagination  */}
            {Array.from({ length: pageCount }, (_, i) => {
                let numClass = 'pageNumber'
                if (currentPage === i + 1) {
                    numClass = 'bg-primary-300'
                }
                return (
                    <Link to={getPageNumberPath(i)} className={pagerCSS+numClass} key={i + 1}>
                        {i + 1}
                    </Link>
                )
            })}

            {!isLast && (
                <Link to={nextPagePath} className={pagerCSS+nextClass} rel="next">
                    Next Page →
                </Link>
            )}

        </div>
    )
}